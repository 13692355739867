.formContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.inputField,
.fileInput,
.submitButton {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.inputField:focus,
.fileInput:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.fileLabel,
.fileInput {
  display: block;
  cursor: pointer;
}

.fileLabel {
  margin-bottom: 0.5rem;
  color: #495057;
  font-weight: bold;
}

.submitButton {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.submitButton:hover {
  background-color: #0056b3;
}

.formTitle {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 1.5rem;
  color: #333;
}

.fileInput {
  padding: 0.375rem;
}

/* Specific adjustments for better alignment and usability */
.fileInputContainer {
  margin-bottom: 1.5rem;
}

/* Additional styles for responsiveness and visual enhancement */
@media (max-width: 768px) {
  .formContainer {
    width: 95%;
    padding: 1.5rem;
  }
}
