.headerParent {
  display: flex;
  max-width: 100%;
  box-sizing: border-box;
  justify-content: center;
  margin-bottom: 2rem;
}

.Container {
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  width: 100%;
  max-width: 1200px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.linksUnderLogo {
  display: flex;
  flex-direction: row;
  gap:1rem;
}

.linksUnderLogo a {
  text-decoration: none;
  color: black;
}

.logoContainer {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; /* Ensure the logo is centered */
}

.HeaderTextContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}

.HeaderTextContainer a {
  text-decoration: none;
  color: #313131; /* Link color */
  font-family: 'Questrial', sans-serif;
  font-size: var(--font-h1);
  transition: color 0.3s; /* Smooth transition for color change */
  padding-left: 1rem;
}

.HeaderText:hover, .active {
  color: #EA2A2A; /* Hover and active link color */
}

.hamburger, .crossButton {
  display: none; /* Hide hamburger and cross button on desktop */
}

.headerLogoImage {
  object-fit: contain;
  width: 10rem;
}

.desktopOnlyLink {
  display: inline-block; /* Ensure these links are normally displayed */
}

/* Responsive adjustments for mobile view */
@media screen and (max-width: 900px) {
  .desktopOnlyLink {
    display: none; /* Hide these links on mobile */
  }
  .Container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    width: 100%;
    max-width: 1200px;
    position: relative; /* For absolute positioning of the hamburger menu */
  }

  .hamburger {
    display: block; /* Show hamburger menu in mobile view */
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10; /* Ensure it's above other content */
    cursor: pointer;
  }

  .HeaderTextContainer {
    display: none; /* Hide text links by default in mobile view */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #FFFFFF; /* Fullscreen menu background */
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3rem; /* Gap between menu items */
    z-index: 9; /* Below the hamburger icon */
    padding-top: 60px; /* Distance from the top */
  }

  .HeaderTextContainer.open {
    display: flex; /* Display menu when it's open */
  }

  .crossButton {
    display: block; /* Show close button when menu is open */
    position: absolute;
    top: 20px;
    right: 20px;
    color: #3B3B3B; /* Close button color */
    z-index: 11; /* Above the menu */
    cursor: pointer;
  }

  .Container {
    flex-direction: column; /* Stack elements vertically in mobile view */
    align-items: center; /* Center-align children in mobile view */
  }
}
