.googleMapLink {
  border: none;
  border-radius: 2rem;
  width: 100%; // Make the iframe width responsive
  height: 100%; // Adjust height as needed or keep it fixed
  aspect-ratio: 16 / 9; // Maintain aspect ratio for better presentation
}

.joinUsFormContainerMain {
  display: flex;
  flex-direction: row; // Default to row layout
  justify-content: center;
  padding-bottom: 5rem;
  gap: 2rem; // Add some space between the form and the map
  padding-top: 4rem;

  @media screen and (max-width: 768px) {
    flex-direction: column; // Switch to column layout on smaller screens
    align-items: center; // Center the items when in column layout
  }
  
}

.joinUsFormContainer {
  max-width: 1200px;
  width: 40%;

  @media screen and (max-width: 768px) {
    width: 85%; // Adjust form width on smaller screens
    display: flex;
    justify-content: center;
  }
}

.joinUsMapContainer {
  width: 50%; // Set initial width for desktop
  max-width: 615px; // Optional, adjust based on your design requirements

  @media screen and (max-width: 768px) {
    width: 85%; // Adjust map container width on smaller screens
    margin-top: 2rem; // Add some space above the map on mobile
  }
}

.joinUsForm {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  width: 100%;
}

.joinUsFormGroup {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
}

.joinUsFormInput,
.joinUsFormTextarea {
  padding: 8px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  background-color: #e6e6e6;
}

.joinUsFormTextarea {
  height: 150px; /* Larger height for the message field */
  resize: vertical; /* Allows the user to resize the textarea vertically */
}

.joinUsSubmitButtonGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.joinUsSubmitBtn {
  padding: 0.3rem;
  border: none;
  border-radius: 10px;
  background-color: #313131;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 15%;

  &:hover {
    background-color: darken(#313131, 10%);
  }
}

@media screen and (max-width: 768px) {

  .joinUsFormContainerMain {
    padding-top: 4rem;
  }
  .joinUsForm {
    width: 85%;
  }

  .joinUsSubmitBtn {
    width: 45%;
  }
}

@media screen and (max-width: 600px) {
  .joinUsForm {
    width: 90%;
  }
}
