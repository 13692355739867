.galleryContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Fewer columns with larger width */
  grid-auto-rows: minmax(20rem, auto); /* Increased min row height for larger images */
  gap: 2.5rem; /* Adjust gap size if needed */
  padding: 4rem;
  justify-content: center;
  align-items: center;
  padding-top: 12rem;
  max-width: 1200px; /* You can increase this if you want a wider gallery */
  margin: auto;
}

.galleryItem {
  background-color: #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%; /* Increased height for larger image containers */
  width: 100%; /* This will automatically adapt to the column width */
  aspect-ratio: 1 / 1; /* Adjust if you want to change the shape of the containers */
  overflow: hidden;
  object-fit: cover;
}
.galleryItem a {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.galleryItemImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
  max-height: 320px;
}

.galleryItem:hover .galleryItemImage {
  filter: none;
}

/* Responsive adjustments for screens wider than 768px to 500px */
@media screen and (max-width: 768px) {
  .galleryContainer {
    grid-template-columns: repeat(2, 14rem); /* 2 columns for devices */
    gap: 1rem; /* Reduced gap for smaller screens */
    grid-auto-rows: minmax(8rem, auto); /* Adjust row height */
    padding-top: 12rem;
  }

  .galleryItem {
    height: 12rem; /* Adjust height for consistency */
  }
}

/* Further adjustments for screens up to 500px */
@media screen and (max-width: 500px) {
  .galleryContainer {
    grid-template-columns: repeat(2, minmax(130px, 1fr)); /* 2 columns, but more flexible for smaller screens */
    padding: 2rem; /* Reduced padding for tighter spaces */
    gap: 1rem; /* Maintain reduced gap */
    padding-top: 8.5rem;
  }

  .galleryItem {
    height: auto; /* Allow height to adjust based on width to maintain aspect ratio */
  }
}
