.navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.hamburger {
  display: none;
}

.logoContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.headerLogoImage {
  max-width: 100%;
  height: 15rem;
}

.desktopMenuContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-bottom: 2rem;
  gap: 4rem;
}

.navLink {
  text-align: center;
}

.menuContainer {
  display: flex;
  justify-content: center; /* Center the menu items horizontally */
  flex-wrap: wrap; /* Allow menu items to wrap if needed */
  gap: 1rem;
  text-transform: capitalize;
  position: relative; /* Set relative positioning to anchor the children container */
}

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--font-h1);
  font-weight: 400;
  /* min-width: 10rem; */
  min-width: fit-content;
  justify-content: center;
}

.navLink {
  text-decoration: none;
  color: black; /* Customize as needed */
  padding: 0.5rem;
}

.menuItem.selected,
.navLink.selectedLink {
  color: red; /* Customize as needed */
}

.childrenContainer {
  display: none; /* Initially hide children */
}

.childrenContainer > div {
  text-decoration: underline black;
}

.menuItem.selected .childrenContainer {
  display: flex; /* Show children for selected item */
  position: absolute; /* Absolutely position the children container */
  top: 100%; /* Position it just below the menu item */
  left: 50%; /* Start from the middle of the menu container */
  transform: translateX(-50%); /* Center it with respect to its own width */
  flex-direction: row;
  gap: 5rem;
  padding: 0.5rem;
  font-size: var(--font-h2);
  text-transform: capitalize;
  padding-top: 1rem;
  justify-content: center;
}

.aboutUsDescription {
  text-align: justify;
  padding: 10px;
  font-size: 1.3rem;
  line-height: 1.6;
  letter-spacing: 2px;
}

#nav-link-di,
#nav-link-vfx {
  text-transform: uppercase;
}

.childrenContainer > div > .childrenContainer > div > a {
  background: black;
  color: white;
}

.childrenContainer > div > .childrenContainer > div > a.selectedLink {
  background: black;
  color: white;
  text-decoration: underline;
}

@media screen and (max-width: 800px) {
  .headerLogoImage {
    max-width: 100%;
    height: 8rem;
  }
}

@media screen and (max-width: 1095px) {
  .menuItem.selected .childrenContainer {
    gap: 2rem;
  }

  .mobileMenu {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 15;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .hidden {
    display: none;
  }

  #nav-link-about-us,
  #nav-link-join-us,
  #div-link-about-us,
  #div-link-join-us {
    display: none;
  }

  .menuItem.selected .childrenContainer {
    padding: 0rem;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  .hamburger {
    display: block;
  }

  .desktopMenuContainer {
    justify-content: center; /* Center the menu items */
    flex-wrap: wrap; /* Allow items to wrap if there isn't enough space */
  }

  .menuItem,
  .navLink {
    padding: 0.25rem; /* Reduce padding for smaller screens to save space */
    font-size: var(
      --font-h1
    ); /* Optionally reduce font size for smaller screens */
  }

  .menuItem {
    min-width: auto; /* Allow menu items to shrink a bit */
  }

  .menuItem.hideOnMobile {
    display: none; /* Optionally, hide less important menu items on smaller screens to save space */
  }
}

@media screen and (max-width: 400px) {
  .menuItem,
  .navLink {
    padding: 0.25rem; /* Further reduce padding for very small screens */
    font-size: var(
      --font-h4
    ); /* Further reduce font size for very small screens */
  }
}

@media screen and (max-width: 500px) {
  .menuItem.selected .childrenContainer {
    gap: 0rem;
  }
}

.hamburger {
  position: fixed; /* Fix the hamburger icon to ensure it's visible even on scroll */
  top: 0; /* Align to the top of the viewport */
  right: 0; /* Align to the right of the viewport */
  padding: 1rem; /* Add some padding for easier tapping */
  z-index: 20; /* Ensure it's above the mobile menu and other content */
  cursor: pointer; /* Make it clear it's clickable */
}

.mobileMenu.open {
  display: flex; /* Display as flex to enable the center alignment when open */
}

.mobileNavLink {
  text-decoration: none;
  margin: 1rem; /* Space out links */
  font-size: 1.5rem; /* Larger font size for easier clicking */
}

.crossButton {
  position: absolute; /* Position it independently within the mobileMenu */
  top: 1rem; /* Distance from the top */
  right: 1rem; /* Distance from the right */
  cursor: pointer; /* Indicate it's clickable */
  z-index: 21; /* Ensure it's above other content in the mobile menu */
}

@media screen and (max-width: 500px) {
  .desktopMenuContainer {
    gap: 0rem;
  }
}
