.profileImages {
  display: grid; // Use grid layout
  grid-template-columns: repeat(3, 1fr); // Create three columns of equal width
  gap: 1rem; // Space between grid items
  padding: 10px; // Add some padding around the images

  @media (max-width: 768px) { // For mobile devices
    grid-template-columns: 1fr; // Switch to a single column for mobile
  }
}

.profileImageItem {
  width: 100%; // Make image width responsive to the container size
  background-color: #ccc; // Placeholder for images, replace or remove as needed
  object-fit: cover;
  height: auto;
  aspect-ratio: 8/12;
}


video.profileImageItem {
  object-fit: contain;
  height: 100%;
  aspect-ratio: auto;
}