.aboutUsDescription {
  font-size: var(--font-h1);
  font-weight: 400;
  padding-top: 2rem;
  max-width: 1200px;
}

.aboutUsDescriptionContainer {
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
  padding-top:4rem;
}

/* Responsive adjustments for mobile view */
@media screen and (max-width: 768px) {

  .aboutUsDescriptionContainer {
    padding-top:4rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .aboutUsDescription {
    font-size: var(--font-h3);
    font-weight: 400;
    padding-top: 2rem;
    max-width: 1200px;
  }

}