.homePageBanner {
  width: 85%;
  max-width: var(--max-width);
  margin:auto;
  margin-bottom: 10rem;
  .swiper-pagination {
    bottom: 10px; // Adjust pagination position if needed
  }
}

.homepageBannerImage {
  width: 100%;
  height: 100%;
  object-fit: contain; // Ensure images cover the slide area without distortion
  padding-top: 4rem;
}

.swiper-pagination-bullet-active {
  background:white;
}

@media screen and (max-width: 550px) {

  .homepageBannerImage {
    object-fit: cover; // Ensure images cover the slide area without distortion
  }

}
/* Responsive adjustments for smaller screens */
@media screen and (max-width: 768px) {

  .homepageBannerImage {
    padding-top: 4rem;
  }

  .homePageBanner {
    height: 25rem; // Adjust height for mobile devices
    width: 90%; // Slightly increase width for mobile screens
    margin-bottom: 3rem; // Reduce bottom margin for mobile
  }
}

