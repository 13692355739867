@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

/* .navbar {
  font-family: Arial, sans-serif;
  width: 200px;
}

.menuItem {
  margin: 5px 0;
  padding-left: 10px;
}

.menuItem.has-children > button {
  font-weight: bold;
}

.menuItem.selected > button {
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
}

button {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}

button:hover {
  background-color: #e7e7e7;
}

.menuItem.selected button {
  background-color: #f0f0f0; /* Light grey, for example */

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

body {
  font-family: 'Questrial';
  background: #ffffff;
}

.posterDivSection {
  max-height: 500px;
  width: 100%;
}

:root {
  /* General font sizes */
  --font-xs: 1rem; /* Extra small */
  --font-sm: 1.143rem; /* Small */
  --font-md: 1.286rem; /* Medium */
  --font-lg: 1.429rem; /* Large */
  --font-xl: 1.571rem; /* Extra large */

  /* Heading font sizes */
  --font-h1: 1.4rem; /* Title */
  --font-h2: 1.143rem; /* 0.81428571429 * 1.4 */
  --font-h3: 1rem; /* 0.71428571429 * 1.4 */
  --font-h4: 0.786rem; /* 0.56142857143 * 1.4 */
  --font-h5: 0.612rem; /* 0.43714285714 * 1.4 */
  --font-h6: 0.493rem; /* 0.35214285714 * 1.4 */

  /* Line height */
  --line-height: 1.5;

  --max-width: 1200px;
}

/* Responsive font sizes for mobile */
@media (max-width: 768px) {
  :root {
    --font-xs: 0.875rem; /* Smaller extra small */
    --font-sm: 1rem; /* Adjusted small */
    --font-md: 1.125rem; /* Adjusted medium */
    --font-lg: 1.25rem; /* Adjusted large */
    --font-xl: 1.375rem; /* Adjusted extra large */

    /* Adjusted heading font sizes for better readability on mobile */
    --font-h1: 1.25rem; /* Smaller title size */
    --font-h2: 1.125rem; /* Adjusted */
    --font-h3: 1rem; /* Adjusted */
    --font-h4: 0.875rem; /* Adjusted */
    --font-h5: 0.75rem; /* Adjusted */
    --font-h6: 0.625rem; /* Adjusted */
  }
}
