.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
}

.title-images {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.title {
  font-size: var(--font-h1);
  font-weight: 400;
}

.image-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.images-grid-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid-item {
  width: calc(25% - 20px); /* Adjust the width and gap for desktop view */
  height: auto;
}

/* Responsive adjustments for mobile view */
@media screen and (max-width: 768px) {
  .image-grid-item {
    width: calc(
      50% - 20px
    ); /* Adjust for 2 images per row with a gap of 20px */
  }
}
