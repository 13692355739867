.footer-main {
  background: #2c2c2c;
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-container {
  background: #2c2c2c;
  color: #fff8f8;
  padding: 2.6rem;
  width: 100%;
  margin-top: 1rem;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.first-row,
.second-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  font-size: var(--font-h2);
  align-items: flex-start;
}

.third-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fourth-row {
  display: flex;
  justify-content: center;
}

.line {
  width: 100%;
  height: 2px;
  background: #fff;
  margin: 1rem 0;
}


.footer-logo,
.footer-links .links-container ul,
.Second-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.footer-logo img {
  width: 6rem;
  margin-top: 15px;
}

.footer-links ul {
  list-style: none;
  display: flex;
  align-items: center;
}

.footer-links li {
  margin-right: 1.5rem;
}

.footer-links a {
  color: #fff8f8;
  text-decoration: none;
}

.footer-social {
  display: flex;
  align-items: center;
}

.social-icons {
  display: flex;
  align-items: center;
  color: black;
  gap: 2rem;
}

.icon-image {
  width: 2.5rem;
}

.footer-copyright {
  margin-top: 1rem;
  color: #fff;
}

.Second-row a {
  color: #fff8f8;
  text-decoration: none;
}

.Second-row a:hover,
.footer-links a:hover {
  text-decoration: underline;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .fourth-row {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .icon-image {
    width: 1.5rem;
  }
  .footer-container {
    padding: 1.6rem;
  }

  .footer-logo {
    gap: 1.5rem;
    padding-bottom: 5rem;
  }

  .footer-links .links-container ul {
    gap: 0.6rem;
    padding-bottom: 0.6rem;
  }

  .first-row,
  .second-row,
  .third-row {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    margin-bottom: 2rem;
  }

  .footer-links ul {
    flex-direction: column;
  }

  .footer-links li {
    margin-right: 0;
    margin-bottom: 1rem; /* Adds space between links */
    text-align: left;
  }

  .social-icons {
    justify-content: center;
  }
}
