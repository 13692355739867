.profileFormContainerTitle {
  padding-top:5rem;
}

.profileContainer {
  max-width: 1200px;
  width: 90%;
}

.profileContainerMain {
  display: flex;
  justify-content: center;
  padding-top: 12rem;
  padding-bottom: 5rem;
}

.profileName {
  font-size: var(--font-h1);
  color: #313131;
  padding-bottom: 1rem;
}

.selectedPill {
  background-color: #313131 !important; /* Highlight color for selected pills */
  color: white; /* White text color for contrast */
  cursor: pointer;
}

.profileStatsContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-size: var(--font-h3);
  padding-bottom: 1rem;
  flex-wrap: wrap;
}


.profileNameStatsIconsContainer {
  padding-left: 2%;
  padding-bottom: 5rem;
}

.profileBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BtnSVG {
  width: 1.6rem;
}

.profileIconsContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.profilePDFBtn {
  background: #313131;
  border: none;
  border-radius: 2rem;
  width: 8rem;
  height: 1.6rem;
  color: #ffffff;
}

.profileFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profileFormTitle {
  font-size: var(--font-h1);
  font-weight: 400;
  padding-bottom: 4rem;
  padding-left: 2%;
}
.submitButtonContainer {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 20%;
}

.submitBtn {
  width: 100%;
}

.profileFormContainer {
  .profileForm {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    width: 55%;
    font-size: var(--font-h3);

    .formGroup {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 5px; // Space between label and input
      }
    }

    .formInput {
      padding: 8px; // Padding inside the input fields
      border: 1px solid #e6e6e6; // Border color
      border-radius: 5px; // Border radius
      background-color: #e6e6e6; // Background color
    }

    .quantityContainer {
      display: flex;
      align-items: center;
      gap:1rem;
      .quantityBtn {
        width: 25px; // Sets the width of the buttons
        height: 25px; // Sets the height of the buttons
        border-radius: 50%; // Makes the buttons circular
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid; // Sets the border thickness
        cursor: pointer; // Changes the cursor to a pointer on hover
        user-select: none; // Prevents text selection
      
        &:focus,
        &:hover {
          outline: none; // Removes the outline on focus/hover
        }
      }
      
      .decreaseBtn {
        background-color: #FFFFFF; // White background for the decrement button
        border-color: #313131; // Dark border for the decrement button
        color: #313131; // Dark text color for the '-' symbol
      
        &:hover {
          background-color: #f2f2f2; // Slightly darker shade on hover for better user feedback
        }
      }
      
      .increaseBtn {
        background-color: #313131; // Dark background for the increment button
        border-color: #313131; // Dark border for the increment button
        color: #FFFFFF; // White text color for the '+' symbol
      
        &:hover {
          background-color: #434343; // Slightly lighter shade on hover for better user feedback
        }
      }
      
    }

    .submitButtonGroup {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .submitBtn {
      padding: 0.3rem; // Padding inside the submit button
      border: none; // Removes the default border
      border-radius: 10px; // Border radius
      background-color: #313131; // Background color
      color: #ffffff;
      cursor: pointer; // Cursor changes to pointer on hover
      transition: background-color 0.3s; // Smooth transition for background color
      width: 25%;

      &:hover {
        background-color: darken(#313131, 10%); // Darken the button on hover
      }
    }
  }
}

.labelPillsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 75%;
  font-size: var(--font-h4);
  text-align: center;


  .labelPill {
    background-color: #E6E6E6; // Sets the background color of the pills
    border-radius: 10px; // Rounds the corners of the pills
    padding: 0.5rem 1rem; // Adds some padding inside the pills for better visual appearance
    margin-bottom: 1rem; // Ensures there's a 1rem gap between the rows of pills
    flex: 0 1 calc(33.333% - 1rem); // Each pill takes up a third of the container width minus the gap size
    cursor: pointer;

    &:not(:nth-child(3n)) {
      margin-right: 1rem; // Adds a right margin to all pills except every third one in a row
    }
  }
}

/* Responsive adjustments for mobile view */
@media screen and (max-width: 768px) {

  .profileContainerMain {
    padding-top:8rem;
  }
  
  .profileMosaicGalleryContainer {
    grid-template-columns: 1fr; /* Single column for mosaic images */
  }

  /* Adjustments for form elements to ensure they fit well on smaller screens */
  .profileForm, .formInput, .submitBtn {
    width: 90%; /* Adjust width for form elements */
  }
  .labelPillsContainer .labelPill {
    background-color: #E6E6E6;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    flex-grow: 0;
    width: 100%;
  }


.profileFormContainer .profileForm {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  width: 85%;
  font-size: var(--font-h3);
}
.profileFormContainer .profileForm .submitBtn {
  padding: 0.3rem;
  border: none;
  border-radius: 10px;
  background-color: #313131;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 45%;
}
}

@media screen and (max-width: 600px) {
  .labelPillsContainer {
    display: flex;
    margin-top: 1rem;
    width: 55%;
    font-size: var(--font-h6);
    text-align: center;
    flex-direction: column;
}

}